import React from "react";
import Footer from "../Footer/Footer";
import Form from "./Form";

export default function NewAccount() {
	return (
		<div>
			<Form></Form>
			<Footer></Footer>
		</div>
	);
}
